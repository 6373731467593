import {useAuthState} from "react-firebase-hooks/auth";
import {auth, setupNotifications} from "../../firebase/firebase";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {hasAdmin} from "../../services/menu";
import Heading from "../Heading";
import {Editor} from "@tinymce/tinymce-react";
import {doGet, doPostBody, imageUploadHandler} from "../../services/rest";
import PopUp from "../PopUp";
import GroupSelect from "./GroupSelect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faThumbsUp, faTrash} from "@fortawesome/free-solid-svg-icons";
import UserSelect from "./UserSelect";
import HtmlStreamView from "../stream/HtmlStreamView";
import MySpinner from "../MySpinner";
import moment from 'moment';
import {formatDistanceToNow, parseISO} from "date-fns";
import {defaultColor} from "../../defaults/AppConfig";

function MessageForm() {
    const [user, loading] = useAuthState(auth);

    const location = useLocation();

    const navigate = useNavigate();

    const [messages, setMessages] = useState({})

    const [showPopup, setShowPopup] = useState(false)

    const [showUserPopup, setShowUserPopup] = useState(false)

    const [showPreview, setShowPreview] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const [hideForEdit, setHideForEdit] = useState(false)


    const onChange = event => {
        setMessages({...messages, [event.target.name]: event.target.value});
    }
    const onEditChange = event => {
        setMessages({...messages, 'message': event});
    }

    const onCheckBoxChange = event => {
        setMessages({...messages, 'sendEmail': event.target.checked});
    }
    const timeAgo = (date) => {
        return formatDistanceToNow(parseISO(date), { addSuffix: true });
    };

    const deleteComment = async (commentId) =>{
        if(window.confirm("Are you sure you want to delete this comment?")){
            const resp = await doGet(`message-admin/delete-comment/${messages.id}/${commentId}`)
            setMessages(resp);
        }
    }

    const isShceduledUpdate = useRef(false)

    useEffect(() => {
        if(location?.state?.message){
            console.log(location.state.message)
            setHideForEdit(true)
            setMessages(location.state.message)
        }else{
            setMessages(prevMessages => ({...prevMessages, expireDateApn: moment().add(7, 'days').format('YYYY-MM-DD')}))
        }
        if(location?.state?.scheduled){
            isShceduledUpdate.current = true
            setMessages(location.state.scheduled)
        }
    }, [location]);

    useEffect(() => {
        if(loading) return
        if(!user) navigate("/login");
        if(!hasAdmin("ROLE_MESSAGING")) navigate("/")
    }, [loading, user, navigate]);

    const onGroupSelected = (group) =>{
        const items = messages.sendToLocations ?? []
        const hasValue = items.some(item => item.id === group.id)
        if(hasValue){
            setMessages({...messages, sendToLocations: [...items.filter(item => item.id !== group.id)]});
        }else{
            setMessages({...messages, sendToLocations: [...items, group]});
        }
    }

    const onUserSelect = (user) =>{
        const items = messages.sendToUsers ?? []
        const hasValue = items.some(item => item.id === user.id)
        if(hasValue){
            setMessages({...messages, sendToUsers : [...items.filter(item => item.id !== user.id)]});
        }else{
            setMessages({...messages, sendToUsers : [...items, user]});
        }
    }

    const userChecked = (userId) => {
        const items = messages.sendToUsers ?? []
        return items.some(item => item.id === userId);
    }

    const groupChecked = (groupId) => {
        const items = messages.sendToLocations ?? []
        return items.some(item => item.id === groupId);
    }

    const sendMessage = async () =>{
        if(!messages.title){
            alert("Please enter a title")
            return
        }
        if(!messages.message){
            alert("Please enter a message")
            return
        }
        if(!messages.sendToLocations && !messages.sendToUsers){
            alert("Please select at least one group or user to send the message to.")
            return
        }

        setIsLoading(true)
        if(isShceduledUpdate.current){
            const response = await doPostBody(`message-admin/update/scheduled`, messages)
            if(response.toString() === 'true'){
                navigate("/send-message")
            }else{
                alert("Something has gone wrong, your scheduled message has not been updated")
                setIsLoading(false)
            }
            return
        }
        if(hideForEdit){
            const response = await doPostBody(`message-admin/update`, messages)
            setMessages(response)
            alert("Successfully updated message")
            setIsLoading(false)
        }else{
            const resp = await doPostBody('message-admin/send-message', messages);
            if(resp.toString() === 'true'){
                navigate("/")
            }else{
                window.alert("Something went wrong you message has not sent.")
                setIsLoading(false)
            }
        }
    }

    const deleteMessage = async () =>{
        if(isShceduledUpdate.current){
            if(window.confirm("Are you sure you want to delete this scheduled message?")){
                setIsLoading(true)
                const resp = await doGet(`message-admin/unschedule/${messages.id}`)
                if(resp.toString() === 'true'){
                    alert("This message has been deleted")
                    navigate("/send-message")
                }else{
                    alert("Something has gone wrong, your scheduled message has not been deleted")
                    setIsLoading(false)
                }
            }
        }else{
            let message = "Are you sure you want to delete this message?"
            if(!messages.firebaseLink){
                message +=" This will not delete from the old version of the app."
            }
            if(window.confirm(message)){
                setIsLoading(true)
                const resp = await doGet(`message-admin/delete/${messages.id}`)
                if(resp.toString() === 'true'){
                    alert("this message has been deleted.")
                    navigate("/send-message")
                }else{
                    alert("Something has gone wrong, we could not delete the message")
                    setIsLoading(false)
                }
            }
        }
    }

    const myHeading = () => {
        if(hideForEdit || isShceduledUpdate.current){
            return [<div style={{cursor: "pointer"}} onClick={deleteMessage}><FontAwesomeIcon icon={faTrash} /></div>]
        }
        return []
    }

    const didLike = (id) => {
        if(messages.likes?.some(like => like?.user?.id === id)){
            return <FontAwesomeIcon icon={faThumbsUp} color={defaultColor} />
        }
        return '';
    }

    if(isLoading){
        return (<>
            <Heading title={'Send Message'} />
            <MySpinner />
        </>)
    }




    return (<>
        <Heading title={'Send Message'}  icons={myHeading()}/>
        <div className={'inner-container'} style={{paddingTop: 50}} >
           <div style={{display: "flex"}}>
               <input className={'search-input'} type={'text'} name={'title'} placeholder={'Message Title'} onChange={onChange} value={messages.title} />
           </div>
        </div>
        <div className={'inner-container'} style={{paddingTop: 50}} >
            <Editor
                onEditorChange={(e) => onEditChange(e)}
                licenseKey={'gpl'}
                value= {messages.message}
                init={{
                    height:500,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'table'
                    ],
                    toolbar: 'undo redo | blocks | link table|' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | image | bullist numlist outdent indent | ' +
                        'removeformat | toggle',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    automatic_uploads: true,
                    images_upload_handler: imageUploadHandler,
                }}
                tinymceScriptSrc={'/tinymce/tinymce.min.js'}
            />
        </div>
        <div className={'inner-container'} style={{paddingTop: 50}}>
            {!hideForEdit && <div style={{display: "flex"}}>
                <div className={'survey-button-container'} style={{flex: 1}}>
                    <input id={'send-email'} type={'checkbox'} className={'survey-radio'} name={'sendEmail'}
                           value={'true'} onChange={onCheckBoxChange} checked={messages.sendEmail ?? false}/>
                    <label className={'checkbox-label'} htmlFor={'send-email'}>Send Email</label>
                </div>
                <div style={{display: 'flex', flex: 1}}>
                    <label style={{flex: 1, paddingTop: 13}}>Expiration date</label>
                    <input style={{flex: 1}} className={'search-input'} type={'date'} name={'expireDate'}
                           placeholder={'Set Expiration Date'} onChange={onChange} value={messages.expireDate}/>
                </div>
            </div>}
            {!hideForEdit && <div style={{display: "flex", paddingTop: 10}}>
                <div style={{flex: 1, display: 'flex'}}></div>
                <div style={{display: 'flex', flex: 1}}>
                    <label style={{flex: 1, paddingTop: 13}}>Push Expiration date</label>
                    <input style={{flex: 1}} className={'search-input'} type={'date'} name={'expireDateApn'}
                           placeholder={'Set Expiration Date'} onChange={onChange} value={messages.expireDateApn}/>
                </div>
            </div>}
            {!hideForEdit && <div style={{display: "flex", alignItems: 'space-between', paddingTop: 30}}>
                <label style={{flex: 1, paddingTop: 13, textAlign: 'left'}}>Schedule Message</label>
                <input style={{flex: 1}} className={'search-input'} type={'datetime-local'} name={'scheduleMessage'}
                       placeholder={'Set Expiration Date'} onChange={onChange} value={messages.scheduleMessage}/>
            </div>}
            <div style={{display: "flex", paddingTop: 30, justifyContent: 'space-around'}}>
                <div>
                    <div style={{color: 'blue', cursor: 'pointer'}} onClick={() => setShowPopup(true)}>Select Groups
                    </div>
                    {messages.sendToLocations?.map((data, index) => (
                        <div style={{display: "flex", paddingTop: 10}}>
                            <div key={index}>{data.locationName}</div>
                            <div onClick={() => onGroupSelected(data)} style={{paddingLeft: 15}}><FontAwesomeIcon
                                icon={faTrash}/></div>
                        </div>
                    ))}
                </div>
                <div>
                    <div style={{color: 'blue', cursor: 'pointer'}} onClick={() => setShowUserPopup(true)}>Select
                        Users
                    </div>
                    {messages.sendToUsers?.map((data, index) => (
                        <div style={{display: "flex", paddingTop: 10}}>
                            <div key={index}>{data.displayName}</div>
                            <div onClick={() => onUserSelect(data)} style={{paddingLeft: 15}}><FontAwesomeIcon
                                icon={faTrash}/></div>
                        </div>
                    ))}
                </div>
            </div>
            {(hideForEdit && !messages.firebaseLink) && <div style={{color: "red", paddingTop: 10 }}><i>***Warning.  This will only update the new version of the app***</i></div>}
            <div className={'flex-space-between'} style={{paddingTop: 30, paddingBottom: 30}}>
                <button className={'submitPost'} onClick={sendMessage}>{hideForEdit ? 'Update Message' : 'Send Message'}</button>
                <button className={'submitPost'} onClick={() => setShowPreview(true)}>Preview Message</button>
            </div>
            {messages.id && <>
            <h4>Comments</h4>
            {messages?.comments?.map((comment, index) => (
                <div key={comment.id} className={'post'}>
                <div className={'post-header'} style={{display: 'flex', justifyContent: 'space-between'}}>
                    <p className={'post-title'}>{comment?.user?.displayName} {timeAgo(comment?.createdAt)}</p>
                    <FontAwesomeIcon cursor={'pointer'} icon={faTrash} onClick={() => deleteComment(comment.id)}
                                     style={{cursor: "pointer"}}/>
                </div>
                <div className={'reset'} dangerouslySetInnerHTML={{__html: comment.comment}}></div>
                </div>
                ))}
            <h5>Read By</h5>
            <table width='100%' className={'table'}>
                <thead>
                <tr>
                    <th>User</th>
                    <th>Time</th>
                    <th>Likes</th>
                </tr>
                </thead>
                <tbody>
                {messages?.readUsers?.map((data, index) => (
                    <tr key={index}>
                        <td>{data.gscUsers.displayName}</td>
                        <td>{moment(data.timestamp).format("MM/DD/YYYY hh:mm a")}</td>
                        <td>{didLike(data.gscUsers.id)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>}
        </div>
        {showPopup && <PopUp
            content={<GroupSelect onGroupSelected={(e) => onGroupSelected(e)} closePopUp={() => setShowPopup(false)}
                                  groupChecked={e => groupChecked(e)}/>}
            handleClose={() => setShowPopup(false)}
        />}
        {showUserPopup && <PopUp
            content={<UserSelect closePopUp={() => setShowUserPopup(false)} onUserSelect={e => onUserSelect(e)} userSelected={e => userChecked(e)} />}
            handleClose={() => setShowUserPopup(false)}
        />}

        {showPreview && <PopUp
            content={<div className={'inner-container'}> <HtmlStreamView stream={messages} /></div>}
                handleClose={() => setShowPreview(false)}
            />}

    </>)

}

export default MessageForm
