import {doGet, doPostBody, mainUrl, uploadPostImages} from "../../services/rest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faTrash, faVideo} from "@fortawesome/free-solid-svg-icons";
import ImageLoadingIndicator from "./ImageLoadingIndicator";
import ImagePreviewViewer from "./ImagePreviewViewer";
import VideoViewer from "./VideoViewer";
import AutoExpandingTextArea from "../inputs/AutoExpandingTextArea";
import {faImage, faSmile} from "@fortawesome/free-regular-svg-icons";
import EmojiPicker from "emoji-picker-react";
import PopUp from "../PopUp";
import React, {useEffect, useState} from "react";
import MyCheckBox from "../MyCheckBox";
import {useSpring, animated} from "@react-spring/web";
import {formatDistanceToNow, parseISO} from "date-fns";
function EditUserPost({messageSend, closeMe, isVisible}){
    const [message, setMessage] = React.useState({'messageType': 'STANDARD'});
    const [emojiOpen, setEmojiOpen] = useState(false)
    const [uploadingImages, setUploadImages] = useState(0)
    const [isPosting, setIsPosting] = useState(false)
    const [showVideoAdd, setShowVideoAdd] = useState(false)
    const [videoUrl, setVideoUrl] = useState();
    const [groups, setGroups] = useState([])
    const [messageEdit, setMessageEdit] = useState();
    const slideInStyles = useSpring({
        from: { transform: 'translateY(100%)' },
        to: { transform: isVisible ? 'translateY(0%)' : 'translateY(100%)' },
        config: { tension: 200, friction: 25 },
    });

    useEffect(() => {
        if(messageSend){
            setMessageEdit(messageSend)
        }
    }, [messageSend]);
    useEffect(()=>{
        doGet('message-admin/groups').then(data => setGroups(data))
    },[])
    useEffect(() => {
        console.log(messageEdit)
        if(messageEdit){
            setMessage(messageEdit)
        }
    }, [messageEdit])
    const createPost = async event => {
        event.preventDefault()
        if(!message?.message && !message?.messageImages && !message?.video){
            window.alert("Whoops, you need to add some content to submit a post.")
            return
        }
        setIsPosting(true)
        const resp = await doPostBody(`message-admin/update-stream-message`, message)
        if(!resp.success){
            window.alert(resp.error)
        }else{
            if(closeMe){
                closeMe()
            }
        }
        setIsPosting(false)

    }

    const deleteComment = async (commentId) =>{
        if(window.confirm("Are you sure you want to delete this comment?")){
            const resp = await doGet(`message-admin/delete-comment/${message.id}/${commentId}`)
            setMessageEdit(resp);
        }
    }

    const emojiClick = (emojiData, event)=>{
        setMessage(prevMessage => {
            return {
                ...prevMessage,
                message: message?.message ?? '' +emojiData.emoji// Append new images
            };
        });
        setEmojiOpen(false)
    }

    const updateTextField = event => {
        setMessage(prevMessage => ({...prevMessage, message: event.target.value}));
    }
    const handleFileUpload = event =>{
        const formData = new FormData()
        for(let i = 0; event.target.files.length > i; i++){
            formData.append('files', event.target.files[i])
        }
        setUploadImages(event.target.files.length)
        uploadPostImages(formData).then(images =>{
            setMessage(prevMessage => ({
                ...prevMessage,
                messageImages: [...(prevMessage.messageImages || []), ...images]  // Append new images
            }));
            setUploadImages(0)
        }).catch(err =>{
            window.alert("Your image was too large or the wrong format, please try again.");
            setUploadImages(0)
        })
    }

    const addUrl = () =>{
        let regularExpression  = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
        let okVideo = regularExpression.test(videoUrl);
        if(!okVideo){
            regularExpression  = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
            okVideo = regularExpression.test(videoUrl)
        }
        if(okVideo){
            setMessage(prevMessage => {
                return {
                    ...prevMessage,
                    videoLink: videoUrl  // Append new images
                };
            });
            setVideoUrl(undefined)
            setShowVideoAdd(false)
        }else{
            window.alert("A valid Vimeo or Youtube URL must be entered.")
        }
    }

    const removeImageByIndex = (index) =>{
        setMessage(prevMessage => {
            const newImages = (prevMessage.messageImages || []).filter((_, i) => i !== index);
            return {
                ...prevMessage,
                messageImages: [...newImages]  // Append new images
            };
        });
    }
    const timeAgo = (date) => {
        return formatDistanceToNow(parseISO(date), { addSuffix: true });
    };
    const removeVideoLink = () =>{
        setMessage(prevMessage => {
            return {
                ...prevMessage,
                videoLink: undefined  // Remove video link
            };
        });
    }
    const isChecked = (itemId) => {
        return message?.sendToLocations?.some(location => location.id === itemId);
    }
    const toggleCheckBox = (item) => {
        if(message?.sendToLocations?.some(location => location.id === item.id)){
            setMessage(prevState => {
                return {
                    ...prevState,
                    sendToLocations: prevState.sendToLocations.filter(location => location.id !== item.id)
                }
            })
        }else{
            setMessage(prevState => {
                return {
                    ...prevState,
                    sendToLocations: [...(prevState?.sendToLocations || []), item]
                }
            })
        }
    }
    return (
        <div style={overlayStyles} onClick={closeMe}>
            <animated.div style={{...pageStyles, ...slideInStyles}} onClick={e => e.stopPropagation()}>
                <form className="createPostForm" onSubmit={createPost}>
                    <div className={'flex-space-between'}>
                        <div className="post-header">
                            <img src={mainUrl + 'menu/image/' + messageEdit?.user?.id}
                                 className={'profile-pic'}/>
                            <p className={'post-title'}><i>What's on your mind?</i></p>
                        </div>
                        <FontAwesomeIcon icon={faClose} onClick={closeMe} style={{cursor: "pointer"}}/>
                    </div>
                    <div style={{display: "flex"}}>
                        <ImageLoadingIndicator number={uploadingImages}/>
                        <ImagePreviewViewer images={message?.messageImages}
                                            onRemove={(index) => removeImageByIndex(index)}/>
                    </div>
                    {message.videoLink &&
                        <VideoViewer video={message.videoLink} admin={true} removeVideo={() => removeVideoLink()}/>}
                    <AutoExpandingTextArea value={message.message} onChange={updateTextField}/>
                    <h4>Sent to the following groups</h4>
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        {groups.map(group => (<><MyCheckBox id={group.id} label={group.locationName}
                                                            checked={isChecked(group.id)} onChange={() => toggleCheckBox(group)}/></>))}
                    </div>
                    <div className={'flex-space-between'} style={{alignItems: 'center'}}>
                        <div className={'flex'}>
                            <input
                                type="file"
                                id="fileUpload"
                                style={{display: 'none'}}
                                multiple
                                onChange={handleFileUpload}
                                accept=".png, .jpg, .jpeg"
                            />
                            <label htmlFor={'fileUpload'} style={{cursor: 'pointer'}}><FontAwesomeIcon
                                icon={faImage}/></label>
                            <div style={{paddingLeft: 10, cursor: 'pointer'}}><FontAwesomeIcon icon={faVideo}
                                                                                               onClick={() => setShowVideoAdd(true)}/>
                            </div>
                            <div style={{paddingLeft: 10, cursor: 'pointer'}}><FontAwesomeIcon icon={faSmile}
                                                                                               onClick={() => setEmojiOpen(true)}/>
                            </div>

                            <div style={{position: 'absolute'}}>
                                <EmojiPicker open={emojiOpen} onEmojiClick={emojiClick}/>
                            </div>

                        </div>
                        <div className={'flex'}>
                            {isPosting ? <div style={{
                                border: '16px solid #f3f3f3',
                                borderRadius: '50%',
                                borderTop: '16px solid #e97200',
                                width: '10px',
                                height: '10px',
                                marginLeft: 10,
                                animation: 'spin 2s linear infinite'
                            }}/> : <button className="submitPost" type="submit" style={{marginLeft: 10}}>
                                Update Post
                            </button>}
                        </div>
                    </div>
                </form>
                {messageEdit?.comments?.map(comment => (<div key={comment.id} className={'post'}>
                    <div className={'post-header'} style={{display: 'flex', justifyContent: 'space-between'}}>
                        <p className={'post-title'}>{comment?.user?.displayName} {timeAgo(comment?.createdAt)}</p>
                        <FontAwesomeIcon cursor={'pointer'} icon={faTrash} onClick={() => deleteComment(comment.id)} style={{cursor: "pointer"}} />
                    </div>
                    <div className={'reset'} dangerouslySetInnerHTML={{__html: comment.comment}}></div>
                </div>))}
                {showVideoAdd && <PopUp
                    handleClose={() => setShowVideoAdd(false)}
                    content={<>
                        <div className={'createPostForm'}>
                            <div className={'post-header'}>
                                    <p><i>Enter youtube or vimeo URL</i></p>
                                </div>
                                <input type={'text'} className={'fancy-input'}
                                       onChange={e => setVideoUrl(e.target.value)}/>
                                <div style={{paddingTop: 20}}>
                                    <button className={'submitPost'} onClick={addUrl}>Add</button>
                                </div>
                            </div>
                        </>}
                    />}
                </animated.div>
                    </div>
                    )
                }
                const overlayStyles = {
    position: 'fixed',
        top
:
    0,
        left
:
    0,
        right
: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
};

const pageStyles = {
    width: '100%',
    maxWidth: '90%',
    height: '100%',
    maxHeight: '90%',
    backgroundColor: 'white',
    borderRadius: '10px 10px 0 0',
    padding: '20px',
    boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
    overflowY: 'auto',
};
export default EditUserPost;
