import {useAuthState} from "react-firebase-hooks/auth";
import {auth, useSetupNotifications} from "./firebase/firebase";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Heading from "./componants/Heading";
import {getName, hasAdmin} from "./services/menu";
import {doGet} from "./services/rest";
import MySpinner from "./componants/MySpinner";
import HtmlStreamView from "./componants/stream/HtmlStreamView";
import Subscriber from "./componants/stream/SubScriber";
import NewPost from "./componants/stream/NewPost";
import PersonalSubscriber from "./componants/stream/PersonalSubscriber";
import NewStreamMessageType from "./componants/stream/NewStreamMessageType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faSearch, faStream} from "@fortawesome/free-solid-svg-icons";
import {useStompClient, useSubscription} from "react-stomp-hooks";
import PopUp from "./componants/PopUp";
import ViewComments from "./componants/stream/ViewComments";

function Home() {
    useSetupNotifications()
    const client = useStompClient();

    const [user, loading] = useAuthState(auth);

    const navigate = useNavigate();

    const [title, setTitle] = useState("GSC")

    const [stream, setStream] = useState()

    const [isLoading, setIsLoading] = useState(true)

    const [listeners, setListeners] = useState([])

    const [hideNewPost, setHideNewPost] = useState(false)

    const messageAdmin = hasAdmin("ROLE_MESSAGING")

    const [page, setPage] = useState(0);

    const [showSearch, setShowSearch] = useState(false);

    const [isSearch, setIsSearch] = useState(false);

    const [showCommentsPage, setShowCommentsPage] = useState();

    useSubscription(`/topic/comments`, (message) => {
        const update = JSON.parse(message.body);
        const index = stream.content.findIndex(item => item.id === update.id);
        if(index > -1){
            //then we know we are displaying this message we need to do something...
            setStream(prevState => {
                const content = [...prevState.content];
                if(update.type === 'comments'){
                    content[index] = {...content[index], comments: update.comments};
                }else{
                    content[index] = {...content[index], likes: update.likes};
                }
                return {...prevState, content: content}
            })
        }
    })

    useEffect(() => {
        if(loading) return;
        if(!user) navigate("/login")
        setTitle(getName())
    }, [user, loading, navigate]);

    useEffect(() => {
        if(!isSearch){
            function checkScrollPosition() {
                if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                    // When the user has scrolled to bottom,
                    // call your function to load more content here.
                    setPage(prevPage => prevPage + 1);
                }
            }
            window.addEventListener("scroll", checkScrollPosition);

            // Clean up the listener when the component is unmounted
            // to avoid memory leaks
            return () => window.removeEventListener("scroll", checkScrollPosition);
        }
    }, [isSearch]);



    useEffect(() => {
        if(user){
            doGet('stream/listeners').then(listeners => setListeners(listeners));
        }
    },[user])

    useEffect(() => {
        if(user && !isSearch){
                 doGet(`stream?page=${page}`).then(data => {
                if(stream){
                    // if stream exists, data is appended to stream.content
                    setStream(prevStream => ({...prevStream, content: [...prevStream.content, ...data.content]}))
                } else {
                    // if stream doesn't exist, simply assign data to stream
                    setStream(data);
                }
                setIsLoading(false)
            })
        }
    }, [user, page, isSearch]);


    if(isLoading){
       return(<>
           <Heading title={title}/>
           <MySpinner />
       </>)
    }

    const getHeading = () => {
        const heading = [isSearch ? <FontAwesomeIcon onClick={()=>{
            setStream({content: []})
            setIsSearch(false)
            setPage(0)
        }} cursor={'pointer'} icon={faClose} /> : <FontAwesomeIcon onClick={() => setShowSearch(true)} cursor={'pointer'} icon={faSearch} />]
        if(messageAdmin){
               heading.push(<div onClick={() => navigate("/send-message")} style={{cursor: "pointer", paddingLeft: 10}}><FontAwesomeIcon icon={faStream} /></div>)
        }
        return heading
    }

    const onMessage = (message) => {
        const theMessage = JSON.parse(message);
        if(theMessage?.type === 'delete'){
            setStream(prevStream => {
                // Filter out the item with the specific id
                const updatedContent = prevStream.content.filter(item => item.id !== theMessage.message);

                // Return the new stream state
                return { ...prevStream, content: updatedContent };
            });
        }else{
            setStream(prevStream => {

                // Find the index of the item with the ID
                const index = prevStream.content.findIndex(item => item.id === theMessage.id);

                let updatedContent = [...prevStream.content];

                // If it exists, replace it
                if (index > -1) {
                    updatedContent[index] = theMessage;
                } else {
                    // If it does not exist, add it
                    updatedContent = [theMessage, ...prevStream.content];
                }

                // Return updated stream
                return { ...prevStream, content: updatedContent };
            });
            doGet(`stream/mark-read/${theMessage.id}`)
        }

    }

    const search = event => {
        if(event.key === 'Enter' && event.target.value.length > 2){
            doGet('stream/search?search=' + event.target.value).then(result => {
                if(result.length === 0){
                    window.alert("There are no results for your search")
                }else{
                    setStream({content: result})
                    setIsSearch(true)
                    setShowSearch(false)
                }
            })
        }
    }




    return(<>
        <Heading title={title} icons={getHeading()}/>
        {listeners.map((listener, index) => (
            <Subscriber key={index} listener={listener.id} onMessage={onMessage} />
        ))}
        <PersonalSubscriber onMessage={onMessage} />
        <div className={'inner-container'} style={{paddingTop: 20}}>
            {!hideNewPost && <NewPost groups={listeners} />}
            {stream.content.map(data =>{
                if(data.messageType === "STANDARD"){
                    return <NewStreamMessageType stream={data} groups={listeners} setHideEditor={e => setHideNewPost(e)} onCommentClick={e => setShowCommentsPage(e)}/>
                }
                return <HtmlStreamView stream={data} onCommentClick={e => setShowCommentsPage(e)} />
            })}
        </div>
        {showSearch && <PopUp
            content={<div className={'inner-container'}>
                <input class={'search-input'} type={'text'} placeholder={'Search For Messages'} onKeyDown={e => search(e)} />
            </div>}
            handleClose={() => setShowSearch(false)}
        />}
        {showCommentsPage && <ViewComments id={showCommentsPage} isVisible={showCommentsPage} onClose={() => setShowCommentsPage(undefined)} />}
    </>)

}

export default Home
