import {useSpring, animated} from "@react-spring/web";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import MySpinner from "../MySpinner";
import {doGet, doPost} from "../../services/rest";
import NewStreamMessageType from "./NewStreamMessageType";
import HtmlStreamView from "./HtmlStreamView";
import {Editor} from "@tinymce/tinymce-react";
import {useSubscription} from "react-stomp-hooks";
import MainComponent from "./Comment";

function ViewComments ({id, isVisible, onClose}){
    const slideInStyles = useSpring({
        from: { transform: 'translateY(100%)' },
        to: { transform: isVisible ? 'translateY(0%)' : 'translateY(100%)' },
        config: { tension: 200, friction: 25 },
    });
    const [stream, setStream] = useState();
    const [comment, setComment] = useState();
    const [isPosting, setIsPosting] = useState(false);
    useSubscription(`/topic/comment/${id}`, (message) => setStream(prevState => ({...prevState, comments: JSON.parse(message.body)})));
    useSubscription(`/topic/likes/${id}`, (message) => setStream(prevState => ({...prevState, likes: JSON.parse(message.body)})));
    useEffect(()=>{
        doGet(`stream/load/${id}`).then(resp => setStream(resp))
    }, [id])
    const submitComment = () => {
        if(!comment){
            window.alert("Please enter a comment");
        }
        setIsPosting(true);
        const formDate = new FormData();
        formDate.append("comment", comment);
        formDate.append("message", id);
        doPost("stream/submit-comment", formDate).then(resp => {
            if(!resp.success){
                window.alert("Something has gone wrong we could not submit your comment.")
            }else{
                setComment('');
            }
            setIsPosting(false);
        })
    }
    return (<div style={overlayStyles} onClick={onClose}>
        <animated.div style={{...pageStyles, ...slideInStyles}} onClick={e => e.stopPropagation()}>
            <div className={'flex'} style={{justifyContent: "flex-end"}}>
                <FontAwesomeIcon icon={faClose} onClick={onClose} />
            </div>
            <div style={{width : '100%', maxWidth: '800px', margin: '0 auto'}}>
            {stream ? <div>
                {stream.messageType === "STANDARD" ? <NewStreamMessageType stream={stream} onCommentClick={undefined} />
                : <HtmlStreamView stream={stream} onCommentClick={undefined}  />}
            </div> : <MySpinner />}
                <div>
                    <Editor
                        licenseKey={'gpl'}
                        onEditorChange={event => setComment(event)}
                        value={comment}
                        init={{
                            setup: function(editor){
                                editor.on('init', function () {
                                    const toolbar = editor.getContainer().querySelector('.tox-toolbar__primary');
                                    if (toolbar) {
                                        toolbar.style.display = 'none'; // Hide toolbar initially
                                    }
                                });

                                editor.on('focus', function () {
                                    const toolbar = editor.getContainer().querySelector('.tox-toolbar__primary');
                                    if (toolbar) {
                                        toolbar.style.display = 'flex'; // Show toolbar on focus
                                    }
                                });

                                editor.on('blur', function () {
                                    const toolbar = editor.getContainer().querySelector('.tox-toolbar__primary');
                                    if (toolbar) {
                                        toolbar.style.display = 'none'; // Hide toolbar on blur
                                    }
                                });
                            },
                            placeholder: 'Add a comment',
                            height: 100,
                            width: 830,
                            menubar: false,
                            statusbar: false,
                            toolbar:
                                'bold italic underline | bullist numlist | link | undo redo',
                            plugins: 'link lists',
                            content_style: 'body { font-family: Arial, sans-serif; font-size: 14px }',
                        }}
                        tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                    />
                    <div className={'flex'} style={{justifyContent: "flex-end"}}>
                        {isPosting ? <div style={{
                            border: '16px solid #f3f3f3',
                            borderRadius: '50%',
                            borderTop: '16px solid #e97200',
                            width: '10px',
                            height: '10px',
                            marginLeft: 10,
                            animation: 'spin 2s linear infinite'
                        }}/> : <button onClick={submitComment} className={'submitPost'}>Comment</button>}
                    </div>
                </div>
                <MainComponent stream={stream} />
            </div>
        </animated.div>
    </div>)
}
const overlayStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
};

const pageStyles = {
    width: '100%',
    maxWidth: '90%',
    height: '100%',
    maxHeight: '90%',
    backgroundColor: 'white',
    borderRadius: '10px 10px 0 0',
    padding: '20px',
    boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
    overflowY: 'auto',
};
export default ViewComments;
