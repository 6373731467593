import {formatPrittyDate} from "../../services/dates";
import MaleThumbImage from '../../images/photo-placeholder-male-thumb.jpg';
import {doGet, mainUrl} from "../../services/rest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import {defaultColor} from "../../defaults/AppConfig";
import {useEffect, useState} from "react";
import {auth} from "../../firebase/firebase";

function HtmlStreamView({stream, onCommentClick}) {
    const [likesCount, setLikesCount] = useState(stream?.likes?.length ?? 0);
    const [commentsCount, setCommentsCount] = useState(stream?.comments?.length ?? 0);
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        setLikesCount(stream?.likes?.length ?? 0);
        setCommentsCount(stream?.comments?.length ?? 0);
    }, [stream.likes, stream.comments]);
    useEffect(() => {
        setChecked(stream?.likes?.some(data => data.user.id === auth.currentUser.uid));
    }, [stream.likes]);
    const submitLine = async () => {
        const submit = await doGet(`stream/like/${stream.id}`)
        if(!submit.success){
            window.alert("Something has gone wrong.")
        }
    }
    return (
        <div className="post">
            <div className="post-header">
                <img src={stream.user ? mainUrl+'menu/image/'+stream.user.id : MaleThumbImage}  className={'profile-pic'}/>
                <p className={'post-title'}><i>Posted By {stream.user ? stream.user.displayName : stream.sentBy}  on {formatPrittyDate(stream.dateSent)}</i></p>
            </div>
            <h3>{stream.title}</h3>
            {!stream.reversed && stream.attachment && <img style={{maxWidth: '100%', height: "auto"}} src={`https://gsc.app/fb/.uploads/${stream.attachment}`} /> }
            <div className={'reset'} dangerouslySetInnerHTML={{__html: stream.message}}></div>
            {stream.reversed && stream.attachment && <img style={{maxWidth: '100%', height: "auto"}} src={`https://gsc.app/fb/.uploads/${stream.attachment}`} /> }
            <div style={{display: 'flex'}}>
                <div><FontAwesomeIcon onClick={submitLine} cursor={'pointer'} icon={faThumbsUp} style={{paddingRight: 5}} color={!checked ? 'grey' : defaultColor} />{likesCount}</div>
                <div style={{cursor: 'pointer', paddingLeft: 20}} onClick={() => onCommentClick(stream.id)}><FontAwesomeIcon icon={faComment} style={{paddingRight: 5}} color={defaultColor} />{commentsCount}</div>
            </div>
        </div>
    );
}
export default HtmlStreamView;


/*const Post = props => {
    return (
        <div className="post">
            <div className="post-header">
                <img src={props.profilePic} alt="profile" className="profile-pic"/>
                <div>
                    <h2>{props.username}</h2>
                    <h3>{props.timestamp}</h3>
                </div>
            </div>

            <p>{props.message}</p>

            {props.image && <img src={props.image} alt="post" className="post-image"/>}

            <div className="post-reactions">
                <button>Like</button>
                <button>Comment</button>
                <button>Share</button>
            </div>
        </div>
    );
};*/
