import React from 'react';
import { parseISO, formatDistanceToNow } from 'date-fns';

// Utility function to format time difference
const timeAgo = (date) => {
    return formatDistanceToNow(parseISO(date), { addSuffix: true });
};

// Recursive Comment component
const Comment = ({ comment }) => {
    return (
        <div key={comment.id} className={'post'}>
            <div className={'post-header'}>
                <p className={'post-title'}>{comment?.user?.displayName} {timeAgo(comment?.createdAt)}</p>
            </div>
            <div className={'reset'} dangerouslySetInnerHTML={{ __html: comment.comment }}></div>
            {comment.replies && comment.replies.length > 0 && (
                <div className="comment-replies">
                    {comment.replies.map(reply => (
                        <Comment key={reply.id} comment={reply} />
                    ))}
                </div>
            )}
        </div>
    );
};

const CommentsList = ({ comments }) => {
    return (
        <div className="comments-list">
            {comments?.map(comment => (
                <Comment key={comment.id} comment={comment} />
            ))}
        </div>
    );
};

const MainComponent = ({ stream }) => {
    return (
        <div>
            <CommentsList comments={stream?.comments} />
        </div>
    );
};

export default MainComponent;
