import React, {useEffect, useState} from "react";
import {doGet} from "../../services/rest";
import Heading from "../Heading";
import MySpinner from "../MySpinner";
import parse from "html-react-parser";
import moment from "moment/moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import PopUp from "../PopUp";
import EditUserPost from "../stream/EditUserPost";

function MessageBoard() {
    const [messages, setMessages] = useState({});
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [editMessage, setEditMessage] = useState();

    useEffect(()=>{
        doGet(`message-admin/message-board?page=${page}`).then(res =>{
            console.log(res);
            setMessages(res);
            setLoading(false);
        })
    },[page])

    const getPreview = (html, n) => {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(html, "text/html");

        let textContent = '';
        let node = htmlDoc.body.firstChild;
        for (let i = 0; i < n; i++) {
            if (node) {
                textContent += node.textContent + '\n';
                node = node.nextSibling;
            } else {
                break;
            }
        }
        return textContent;
    };

    const formatDate = (dateTime) => {
        return  moment(dateTime).format("MM/DD/yyyy h:mm a");
    }

    const deleteMessage = async (id) =>{
        if(window.confirm("Are you sure you want to delete this message?")){
            setLoading(true)
            const resp = await doGet(`message-admin/message-board/delete/${id}?page=${page}`);
            setMessages(resp)
            setLoading(false)
        }
    }

    const closeMe = async () =>{
        setEditMessage(undefined)
        setLoading(true)
        const resp = await doGet(`message-admin/message-board?page=${page}`)
        setMessages(resp)
        setLoading(false)
    }

    if(loading){
        return(<>
            <Heading title={'Message Board'} />
            <MySpinner />
        </>)
    }

    return (<>
        <Heading title={'Message Board'} />
            <div className={'main-container'}>
                <h3>Message Board</h3>
                <table className="table" style={{width: '100%', borderCollapse: 'collapse'}}>
                    <tr>
                        <th>Sent By</th>
                        <th>Title</th>
                        <th>Preview</th>
                        <th>Date Sent</th>
                        <th>Delete</th>
                    </tr>
                    {messages.content.map(message => (
                        <tr style={{borderBottom: '1px solid gray', cursor: 'pointer'}} key={message.id} onClick={() => setEditMessage(message)}>
                            <td style={{
                                borderBottom: '1px solid gray',
                                marginBottom: 10
                            }}>{message.user?.displayName ?? message.sentBy}</td>
                            <td style={{borderBottom: '1px solid gray', marginBottom: 10}}> {message.title}</td>
                            <td style={{
                                borderBottom: '1px solid gray',
                                marginBottom: 10,
                                textAlign: 'left'
                            }}>{parse(getPreview(message.message, 5))}</td>
                            <td style={{
                                borderBottom: '1px solid gray',
                                marginBottom: 10
                            }}>{formatDate(message.dateSent)}</td>
                            <td style={{
                                borderBottom: '1px solid gray',
                                marginBottom: 10
                            }}><FontAwesomeIcon onClick={() => deleteMessage(message.id)} icon={faTrash} cursor={'pointer'} /></td>
                        </tr>
                    ))}
                </table>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'flex-end',
                    marginTop: '20px',
                    justifyContent: 'space-between',
                    marginBottom: '20px'
                }}>
                    {!messages.first ?
                        <button onClick={() => setPage(page - 1)} className={'submitPost'}>Previous</button> :
                        <div></div>}
                    {!messages.last &&
                        <button onClick={() => setPage(page + 1)} className={'submitPost'}>Next</button>}
                </div>
            </div>
            {editMessage &&
                    <EditUserPost messageSend={editMessage} closeMe={closeMe} isVisible={true}/>
                }
        </>
    )
}

export default MessageBoard;
